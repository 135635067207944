// extracted by mini-css-extract-plugin
export const portfolioContainer = "index-module--portfolioContainer--2ckwQ";
export const swftlime = "index-module--swftlime--ZP8_L";
export const headerContainer = "index-module--headerContainer--3Bh9h";
export const headerNav = "index-module--headerNav--2xZ1L";
export const triangle = "index-module--triangle--51agr";
export const footerNavContainer = "index-module--footerNavContainer--1_nxY";
export const footerNav = "index-module--footerNav--2daFU";
export const vl = "index-module--vl--3qDiZ";
export const hero = "index-module--hero--14Mhx";
export const heroText = "index-module--heroText--1Ghmh";
export const heroConsole = "index-module--heroConsole--asvsZ";
export const heroAbout = "index-module--heroAbout--2vsvj";
export const heroButtons = "index-module--heroButtons--2FBnP";
export const heroButtonProjects = "index-module--heroButtonProjects--2VAde";
export const heroButtonContact = "index-module--heroButtonContact--mNm8m";
export const sectionTitle = "index-module--sectionTitle--3IBiz";
export const projectsContainer = "index-module--projectsContainer--1UGDD";
export const projects = "index-module--projects--1urFM";
export const project = "index-module--project--23Eqn";
export const projectText = "index-module--projectText--3gXV2";
export const projectMarker = "index-module--projectMarker--2r-cM";
export const projectHeader = "index-module--projectHeader--2PBq8";
export const projectTitle = "index-module--projectTitle--2O99p";
export const projectLinks = "index-module--projectLinks--3OQ4D";
export const projectTools = "index-module--projectTools--39HzS";
export const projectTool = "index-module--projectTool--1zcVQ";
export const projectImage = "index-module--projectImage--2c6Cy";
export const projectsButton = "index-module--projectsButton--1sDxI";
export const skillsContainer = "index-module--skillsContainer--2v1wB";
export const skillsMarker = "index-module--skillsMarker--PoCbI";
export const skillsBox = "index-module--skillsBox--1QpPu";
export const skillsGroup = "index-module--skillsGroup--2yCpx";
export const contactContainer = "index-module--contactContainer--1F848";
export const formContainer = "index-module--formContainer--2bZyr";
export const formPerson = "index-module--formPerson--1E8m7";
export const formSend = "index-module--formSend--3URYy";
export const formMarker = "index-module--formMarker--3kpYD";
export const contactOthers = "index-module--contactOthers--TlEjz";
export const contactSocials = "index-module--contactSocials--3rYNP";
export const projectEven = "index-module--projectEven--okDbt";
export const contactContentContainer = "index-module--contactContentContainer--2MbTW";
export const headerLogoNav = "index-module--headerLogoNav--3xHWS";