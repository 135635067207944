import React from "react"

export default function Footer() {
  return (
    <footer>
      <p>
        For you, by me, <i>Khaled Al-Amoodi</i>
      </p>
    </footer>
  )
}
